import React from 'react';
import Swiper from 'react-id-swiper';
import "./swiper.css"

import styled from 'styled-components'
import image1 from "../images/floors/baltic-maple.jpg"
import image2 from "../images/floors/latin-pine.jpg"
import image3 from "../images/floors/verdon-oak.jpg"
import bgImage from "../images/floors/wall.png"
export default class SwiperTest extends React.Component {
  constructor(props) {
    super(props)
    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
    this.swiper = null
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  render() {
    const params = {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      runCallbacksOnInit: true,
      onInit: (swiper) => {
        this.swiper = swiper
      }
    }

    const Wrapper = styled.div`
    overflow: hidden;
    height: 70vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    border:1px solid red;

    background-color:white;
    display:flex;
    flex-direction: column;
    align-self:center;
    justify-content:space-evenly;
    transition: 0.5s;
    padding-bottom:50px;


`

const BackgroundWall = styled.div`
  position:absolute;
  top:0;
  background-image: url(${bgImage});
  background-size:cover;
  background-position: bottom;
  height:50%;
  width:100%;
  z-index:3;
`

const FloorImage = styled.div`
perspective:1000px;
  width:100%;
  height:100%;
  > .swiper-container { transform:rotateX(40deg); }
   > .swiper-container > .swiper-wrapper > .swiper-slide-active > img { 
    width:100% 
  }
  > .swiper-container > .swiper-wrapper > .swiper-slide-active { display:flex;
    margin-left: -55%;
    margin-top: -10%;


  }
`

const FloorSelector = styled.div`

  height:auto;
  width:auto;
  max-width: 200px;
  max-height: 75%;

  display:grid;

  background-color:white;


`


    return (
<Wrapper>
  <FloorImage>
  <Swiper {...params}> 
    <div> <img src={image1} alt='Trevor Wharton Contracts' />
    <img src={image1} alt='Trevor Wharton Contracts' />
    <img src={image1} alt='Trevor Wharton Contracts' />
    </div>
        <div><img src={image2} alt='Trevor Wharton Contracts' /><img src={image2} alt='Trevor Wharton Contracts' /><img src={image2} alt='Trevor Wharton Contracts' /></div>
            <div><img src={image3} alt='Trevor Wharton Contracts' /><img src={image3}  alt='Trevor Wharton Contracts' /><img src={image3}  alt='Trevor Wharton Contracts' /></div>
   </Swiper>
  <BackgroundWall />

   </FloorImage>
  <FloorSelector>

  </FloorSelector>

</Wrapper>
    )
  }
}