import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import "./swiper.css"
import "./swiper-vis.css"

import swiperTest from '../components/swiperTest'

import image1 from "../images/floors/1.jpg"
import image2 from "../images/floors/2.jpg"
import image3 from "../images/floors/3.jpg"
import image4 from "../images/floors/4.jpg"
import image5 from "../images/floors/5.jpg"
import image6 from "../images/floors/6.jpg"
import image7 from "../images/floors/7.jpg"
import image8 from "../images/floors/8.jpg"
import image9 from "../images/floors/9.jpg"
import image10 from "../images/floors/10.jpg"
import image11 from "../images/floors/11.jpg"
import image12 from "../images/floors/12.jpg"

import bgImage from "../images/floors/wall.png"

import FloorLightbox from "../components/FloorLightbox"

import moduImage from "../images/assoc1.png"

import FloorImages from "../components/FloorImages"


const Wrapper = styled.div`
    // overflow: hidden;
    // height: 70vh;
    // width: 100%;
    // margin-left: auto;
    // margin-right: auto;

    // border:1px solid red;

    // background-color:white;
    // display:flex;
    // flex-direction: column;
    // align-self:center;
    // justify-content:space-evenly;
    // // transition: 0.5s;
    // padding-bottom:50px;
    position: relative;
    height: 70vh;
    min-height: 600px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
    padding-bottom: 50px;
    overflow: hidden;

`

const BackgroundWall = styled.div`
  position:absolute;
  top:0;
  background-image: url(${bgImage});
  background-size:cover;
  background-position: bottom;
  height:50%;
  width:100%;
  z-index:3;

  position: absolute;
    top: 0px;
    background-size: cover;
    height: 100%;
    z-index: 3;
    width: 100% !important;
    background-position: center bottom;
`

const VisGrid = styled.div`
grid-template-columns: auto auto auto;
background-image: url(${props => props.bg});
background-size: 100px;
display: grid !important;
background-repeat: repeat;
`

const FloorImage = styled.div`
// perspective:1000px;
//   height:100%;
perspective: 100px;
    perspective-origin: 50% 0%;
    height: 102%;
  > div >.swiper-container { 
    // transform:rotateX(40deg); height:100vh; transition: 0s;
    transform: rotateX(87deg);
    height: 800px;
    width: 2000px;
    transition: all 0s ease 0s;
    overflow:visible;
  }
   > div > .swiper-container > .swiper-wrapper > * { transition: 0s !important;
  }
  > div > .swiper-container > .swiper-wrapper > .swiper-slide-active { display:flex;
    // margin-left: -25%;
    // margin-top: -10%;


  }

  > div > .swiper-container > .swiper-wrapper { 
    // transform: translateZ(0) !important;
  }
`

const FloorSelector = styled.div`

width: 30%;
height: auto;
display: flex;
flex-direction: column;
background-color: rgba(255, 255, 255, 0.8);
position: absolute;
top: 20px;
left: 5%;
z-index: 9999;
overflow: auto hidden;
padding: 2em 1em;


// @media only screen and (min-width: 900px)
// .mob_vis {
//     display: none!important;
// }
`

const Grid = styled.div`
overflow-x: auto;
flex-direction: row;
height: 100%;
width: auto;
display: grid;
grid-template-columns: 100px 100px 100px 100px 100px 100px;
grid-template-rows: 100px 100px;

@media only screen and (max-width: 800px)
{
    grid-template-columns: 50px 50px 50px 50px 50px 50px;
    grid-template-rows: 50px 50px;
    width: 100%;
}
`

const FloorButton = styled.button`

  background-image: url(${props => props.bg});
  width: 100px;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 40px 80px 0px, rgba(0, 0, 0, 0.19) 0px 60px 200px 0px;


  > div { 
    font-size: 10px;
    position: relative;
    top: -35px;
    line-height: 1;
  }

  //     @media (max-width: 900px) {

  //       > div { 
  //         position:absolute; top: 50px; width:50px; height:50px; }
  //       min-width:50px;
  //       min-height:50px;

  // }
  @media (max-width: 900px)
   {
    width: 50px;
    height: 50px;

    > div {     font-size: 8px;
      top: 0px;}
}
`

const Logo = styled.div`
  align-self:center;
  height:60px;
  width:100%;
  background-image: url(${moduImage});
  background-size:contain;
  background-repeat: no-repeat;
  margin-bottom:20px;
`


   const params = {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 0,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
     onInit: (swiper) => {
        this.swiper = swiper
      }
    };

      const gridparams = {
      slidesPerView: 3,
      slidesPerColumn: 3,
      centredSlides: true,
      spaceBetween:0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    };

      const mobparams = {
      slidesPerView: 6,
      centredSlides: true,
      spaceBetween:0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      }
    };

class SwiperTest extends React.Component {
  constructor(props) {
    super(props)
    this.goNext = this.goNext.bind(this)
    this.goPrev = this.goPrev.bind(this)
    this.goTo = this.goTo.bind(this)
    this.swiper = null
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext()
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev()
  }

  goTo(index) {
    console.log("test" + index);
    // console.log(this.swiper.width);
    if (this.swiper) { 
      this.swiper.slideTo(index);
      console.log("swappin'");
    } 
  }

  testShit() { 
    console.log("suck a dick dumb shit");
  }

  render() {
    const params = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      runCallbacksOnInit: true,
      onInit: (swiper) => {
        this.swiper = swiper
      }
    }

    return (
      <div>
        <Swiper {...params} ref={node => { if (node) this.swiper = node.swiper }}>
    <VisGrid bg={image1}> <span>Laurel Oak</span></VisGrid>
        <VisGrid bg={image2}><span>Laurel Oak</span></VisGrid>

      <VisGrid bg={image3}><span>Country Oak</span></VisGrid>

      <VisGrid bg={image4}><span>Country Oak</span></VisGrid>
      <VisGrid bg={image5}><span>Mountain Oak</span></VisGrid>
      <VisGrid bg={image6}><span>Mountain Oak</span></VisGrid>
      <VisGrid bg={image7}><span>Santa Cruz Oak</span></VisGrid>
      <VisGrid bg={image8}><span>Sierra Oak</span></VisGrid>
      <VisGrid bg={image9}><span>Castle Oak</span></VisGrid>
      <VisGrid bg={image10}><span>Castle Oak</span></VisGrid>
      <VisGrid bg={image11}><span>Persian Walnut</span></VisGrid>
      <VisGrid bg={image12}><span>Chester Oak</span></VisGrid>


   </Swiper>
        <button onClick={this.goNext}>Next</button>
        <button onClick={this.goPrev}>Prev</button>
        <FloorSelector className="desk_vis"> 
          <Logo></Logo>
          {/* <Swiper {...gridparams} className="swiper-hide"> */}

            {/*<FloorButton onClick={this.testShit}> test </FloorButton>*/}
          <Grid>

            <FloorButton bg={image1} onClick={() => this.goTo(0)}><div>Laurel Oak</div></FloorButton>
 
            <FloorButton bg={image2} onClick={() => this.goTo(1)}><div>Laurel Oak</div></FloorButton>
 
            <FloorButton bg={image3} onClick={() => this.goTo(2)}><div>Country Oak</div></FloorButton>
             
            <FloorButton bg={image4} onClick={() => this.goTo(3)}><div>Country Oak</div></FloorButton>
             
            <FloorButton bg={image5} onClick={() => this.goTo(4)}><div>Mountain Oak</div></FloorButton>
             
            <FloorButton bg={image6} onClick={() => this.goTo(5)}><div>Mountain Oak</div></FloorButton>
 
            <FloorButton bg={image7} onClick={() => this.goTo(6)}><div>Santa Cruz Oak</div></FloorButton>
 
            <FloorButton bg={image8} onClick={() => this.goTo(7)}><div>Sierra Oak</div></FloorButton>
 
            <FloorButton bg={image9} onClick={() => this.goTo(8)}><div>Castle Oak</div></FloorButton>
             
            <FloorButton bg={image10} onClick={() => this.goTo(9)}><div>Castle Oak</div></FloorButton>
             
            <FloorButton bg={image11} onClick={() => this.goTo(10)}><div>Persian Walnut</div></FloorButton>
             
            <FloorButton bg={image12} onClick={() => this.goTo(11)}><div>Chester Oak </div></FloorButton>
          {/* </Swiper> */}
          </Grid>

        </FloorSelector>
      </div>
    )
  }
}


const FloorVis = ({ title, text, image }) => (

<Wrapper>
  <FloorImage>
   <SwiperTest />
  <BackgroundWall />
   </FloorImage>

</Wrapper>
)


export default FloorVis
