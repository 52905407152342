import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import portfolioImage from '../images/go-bounce.jpg'
import Img from 'gatsby-image'
import Swiper from 'react-id-swiper'
import "./swiper.css"
import port1Image from '../images/garden-centre/img1.jpg'
import port2Image from '../images/garden-centre/img2.jpg'
import port3Image from '../images/garden-centre/img3.jpg'
import { graphql } from "gatsby"


const Wrapper = styled.a`
  overflow:hidden;
  position:relative;
   width:49%;
   max-height:500px;
  margin-top: 5%;
  transition:.5s;

      @media (max-width: 700px) { width:100%; height: 350px; }
border-bottom: 10px solid #96938d;
}
`
const Overlay = styled.div`
  lists-style-type:lower-alpha;
  position: absolute;
  bottom: 0;
  background-color:rgba(243, 238, 235, .75);
  overflow: hidden;
  width: 100%;
  height:auto;
  transition: 1s;
  z-index: 2;
  text-transform:uppercase;
  font-family: 'Lato';
  padding: 1em 1em;
  line-height:3em;

    ${Wrapper}:hover & {
      bottom: -100%;

  }
`

const ImageWrapper = styled.div`
    background-image: url(${props => props.src});
    height:100%;
    width:100%;


}
`

const Text = styled.div`
  // text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: #E2674E;
  padding-top: 20px;
  font-family:'Lato';
  font-weight:900;
`

const Title = styled.div`
font-family:"bebas-neue";
font-weight:900;
font-size:3em;
height:auto;
  // text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color:#E2674E;
  }
`
const swiperParams = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        centeredSlides: true,
        autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      }
    };

const PortfolioCard = ({ title, children }) => (
  <Wrapper>
  {children}

  <Overlay> 
      <Title> {title} </Title> 
  </Overlay>
{/*  <Wrapper href={link} target="_blank" rel="noopener noreferrer">

    <ImageWrapper src={portfolioImage}>    </ImageWrapper>
  </Wrapper>*/}
  </Wrapper>
)

export default PortfolioCard
