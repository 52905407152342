import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import portfolioImage from '../images/go-bounce.jpg'
import Img from 'gatsby-image'
import bgImage from '../images/cta_card.jpg'
import { graphql, Link } from "gatsby"


const Wrapper = styled.div`
  overflow:hidden;
  position:relative;
   width:49%;
  margin-top: 5%;
  transition:.5s;

      @media (max-width: 800px) { width:100%; min-height: 300px; }
border-bottom: 10px solid #96938d;
  background-image: url(${bgImage});
  background-size: cover;

`
const Overlay = styled.div`
display:flex;
flex-direction:column;
  background-color:rgba(227, 103, 78, 0.73);
  overflow: hidden;
  width: 100%;
  height:100%;
  z-index: 2;
  text-transform:uppercase;
  font-family: 'Lato';
  man-width:300px;
  padding: 1em 1em;

`

const QuoteButton = styled(Link)`
  font-family: Roboto;
  font-size:1em;
  letter-spacing:.1em;
  text-transform:uppercase;
  font-weight:700;
  color:white;
  padding:10px;
  margin-top:20px;
  background-color:rgb(227, 103, 78);
  border-radius:25px;
  width:20em;
  align-self:center;
  text-align:center;
  line-height:2em;
  text-decoration: none;
  &:hover { 
    background-color:rgb(214, 147, 102);

  }
`

const Text = styled.div`
  // text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color: white;
  width: 60%;
  padding-top: 20px;
  font-family:'Lato';
  font-weight:300;
  font-size:1em;
  text-transform: none;
`

const TextBox = styled.div`
width:90%;
display:flex;
  position:absolute;
  left:5%;
  bottom:5%;

`

const Title = styled.div`
width:60%;
  position:absolute;
  left:5%;
  top:5%;
font-family:"bebas-neue";
font-weight:500;
font-size:2em;
line-height:1.5em;
height:auto;
  // text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
color:white;
      @media (max-width: 1200px) {
       font-size:1.7em;
       line-height:1em;
     }
      @media (max-width: 900px) {
        font-size:1.6em;
     }
      @media (max-width: 800px) {
       font-size:1.5em;
     }
  }
`

const CTACard = ({ title, children }) => (
  <Wrapper>
    <Overlay>
      <Title> Our Customers Love our service and attention to safety and detail. </Title> 
      <TextBox>
      <Text>
        Request a quote with us now and let us get started on your complete flooring solution.
      </Text>
      <QuoteButton to="/#quote" activeStyle={{ color: "rgb(227, 103, 78)" }}> Get A Free Quote now </QuoteButton>
      </TextBox>
    </Overlay>
  </Wrapper>
)

export default CTACard
