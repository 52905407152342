import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import Img from "gatsby-image"
import SEO from "../components/seo"
import HeroBanner from "../components/PortfolioBanner"
import CActionBanner from "../components/CActionBanner"
import ServiceBoxes from "../components/ServiceBoxes"
import SolutionBoxes from "../components/SolutionBoxes"
import ParallaxBanner from "../components/ParallaxBanner"
import PortfolioCard from "../components/Port4Card"
// import GardenCentre from "../components/portfolio/GardenCentre"
import ContactBox from "../components/ContactBox"
import styled from 'styled-components'

import PortfolioImages1 from "../components/port1"
import PortfolioImages2 from "../components/port2"
import PortfolioImages3 from "../components/port3"
import PortfolioImages4 from "../components/port4"
import PortfolioImages5 from "../components/port5"
import PortfolioImages6 from "../components/port6"
import PortfolioImages7 from "../components/port7"


import Modal from "../components/Modal"

import CTACard from "../components/CTACard"

import FloorVis from '../components/FloorVis'

const ProjectsWrapper = styled.div`
  height:auto;
  display: flex;
  flex-wrap: wrap;
  overflow:hidden;
  padding: 0 20px;
  padding-bottom: 100px;
  justify-content: space-between;
  align-content: space-evenly;
`
const Wrapper = styled.div`
    height:100%;
    width:100%;
`
// function DataTest(data){
//   if (JSON.stringify(data) != "{}") { console.log(JSON.stringify(data));
//       {data.port4.edges.map(image => (
//       <Img key={image.node.childImageSharp.fluid.src }
//             fluid={image.node.childImageSharp.fluid} />
//             ))};
// }
// }


let index = 1;


const PortfolioPage = ({ data }) => {

const [modal, setModal] = useState();

return (
  <Layout>
  <Wrapper>
  
  <SEO title="Walkthrough" keywords={['commercial flooring', 'trevor wharton', 'york', 'flooring contractors', 'flooring installation', 'vinyl flooring']} />

    <HeroBanner />
{/*    <div onClick={() => setModal(index)}>Tits
    <Modal open={index === modal} closeModal={setModal}> Tits and Kitties </Modal>
    </div>*/}
    <ProjectsWrapper>
    <PortfolioCard title="Clement and Church"> <PortfolioImages6 /> </PortfolioCard>
    <PortfolioCard title="Massarella Restaurant"> <PortfolioImages7 /> </PortfolioCard>

                <PortfolioCard title="Go Bounce Doncaster" >     <PortfolioImages4 /> </PortfolioCard>

    <PortfolioCard title="Bridgemere Garden Centre"> <PortfolioImages1 /> </PortfolioCard>
        <CTACard title="Call to Action"/>

        <PortfolioCard title="Crown Hotel Gym" > <PortfolioImages3 /> </PortfolioCard>
                        <PortfolioCard title="Angelica's Cafe"> <PortfolioImages5 /> </PortfolioCard>
        <PortfolioCard title="Coletta and Tyson"> <PortfolioImages2 /> </PortfolioCard>

   </ProjectsWrapper>
   <CActionBanner> REQUEST A FREE QUOTE </CActionBanner>
    <FloorVis />
   
</Wrapper>
 </Layout>
 )
}

export default PortfolioPage

export const query = graphql`
query {
 port4: allFile(filter: {sourceInstanceName: { eq: "port4" }}) {
    edges {
      node {
           childImageSharp {
                fluid(maxWidth:2000) {
                  ...GatsbyImageSharpFluid
                }
              }
      }
    }
}
   port2: allFile(filter: {sourceInstanceName: { eq: "port2" }}) {
    edges {
      node {
           childImageSharp {
                fluid(maxWidth:2000) {
                  ...GatsbyImageSharpFluid
                }
              }
      }
    }
  }
   port3: allFile(filter: {sourceInstanceName: { eq: "port3" }}) {
    edges {
      node {
           childImageSharp {
                fluid(maxWidth:2000) {
                  ...GatsbyImageSharpFluid
                }
              }
      }
    }
  }
   port1: allFile(filter: {sourceInstanceName: { eq: "port1" }}) {
    edges {
      node {
           childImageSharp {
                fluid(maxWidth:2000) {
                  ...GatsbyImageSharpFluid
                }
              }
      }
    }
  }
}
`