import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import useHover from "./userHover";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import { FaTimes } from 'react-icons/fa'

const ServiceIcon = styled.div`
  color: rgb(227, 103, 78);
  align-self:center;
  width:30px;
  height:30px;

  margin-right:20px;

  > svg {
    width:100%;
    height:100%;
  }
  `

const LightboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  grid-gap: 5px;
`;

const Box = styled.div`
  width:100%;
  height:90%;
  z-index: 3;
  overflow:hidden;

  > div, div > .swiper-container { 
    width:100%;
    height:100%;
  }
`

const Box2 = styled.div`
  width:100%;
  height:100%;
  z-index: 3;
  overflow:hidden;

  > div, div > .swiper-container { 
    width:100%;
    height:100%;
  }
`

const Title = styled.div`
font-family:"bebas-neue";
font-weight:900;
font-size:2.5em;
height:auto;
  // text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  color:#E2674E;

  line-height: 40px;
`

const FullHW = styled.div`
  height:100% !important;
  width:80% !important;

  // border: 1px solid red;

  position:absolute;

  top:0;
  left:10%;

  z-index:1;


  > div, div > .swiper-container { 
    width:100%;
    height:100%;
  }

  `

const Overlay = styled.div`
display:flex;
max-height:90px;
justify-content:space-between;
  lists-style-type:lower-alpha;
  // position: absolute;
  // top: 0;
  background-color:white;
  overflow: hidden;
  width: 100%;
  height:auto;
  transition: 1s;
  z-index: 2;
  text-transform:uppercase;
  font-family: 'Lato';
  padding: 1em 1em;
  line-height:3em;
`
const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 3,
                overflow: 'hidden',
    },

  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    transform             : 'translate(-50%, -50%)',
    backgroundColor : '#454951',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  }
};

Modal.setAppElement('#___gatsby')


class Lightbox extends Component {
    constructor(props) {
    super(props)
    // this.goTo = this.goTo.bind(this)
    this.swiper = null
        this.state = {
      hovered: 0
    };
  }

    componentDidMount() {
    this.swiper.autoplay.stop();
  }



  static propTypes = {
    carImages: PropTypes.array.isRequired, // eslint-disable-line
  }

  onMouseEnter() {
    this.swiper.autoplay.start();
    console.log("Mouse Enter");
  }

  onMouseLeave() {
    this.swiper.autoplay.stop();
  }

  render() {

    const { carImages } = this.props;

const swiperParams = {
   spaceBetween: 30,
      centeredSlides: true,
      waitForTransition: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
            navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
        autoplay: {
    delay: 2000,
  },
            runCallbacksOnInit: true,
      onInit: (swiper) => {
        this.swiper = swiper;
        this.swiper.autoplay.stop();
      } 
    }; 
    return (
      <div onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>
      <Swiper {...swiperParams} ref={node => { if (node) this.swiper = node.swiper }} data-swiper-autoplay="200" >
        {carImages.map(image => (
          <Img
            key={image.node.childImageSharp.sizes.src}
            sizes={image.node.childImageSharp.sizes}
          />
        ))}
      </Swiper>
      </div>

    );
  }
}

class FullLightbox extends Component {
    constructor(props) {
    super(props)
    // this.goTo = this.goTo.bind(this)
    this.swiper = null
        this.state = {
      hovered: 0
    };
  }

    componentDidUpdate() {
      console.log("whut");
    this.swiper.autoplay.stop();
    // this.swiper.update();
    console.log("derp");
  }

  onVisible() {
    // this.swiper.update();
    console.log("Test");
  }
  static propTypes = {
    carImages: PropTypes.array.isRequired, // eslint-disable-line
  }


  render() {

    const { carImages } = this.props;

const swiperParams = {
   spaceBetween: 30,
   observer: true,
   observeSlideChildren: true,
observeParents: true,

      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
            navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      onInit: (swiper) => {
        this.swiper = swiper;
        // this.swiper.update();
      }
    }; 

    return (
      <Box>
      <Swiper {...swiperParams} ref={node => { if (node) this.swiper = node.swiper }}>
        {carImages.map(image => (
          <Img
            key={image.node.childImageSharp.sizes.src + 'modal'}
            sizes={image.node.childImageSharp.sizes}
          />
        ))}
      </Swiper>
      </Box>
    );
  }
}


export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.swiperRef = React.createRef();

    this.modalRef = React.createRef();
  }

  openModal() {
    if (this.state.modalIsOpen == false) { 
    this.setState({modalIsOpen: true});
  }
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    // this.subtitle.style.color = '#f00';
    // window.setTimeout(this.swiperRef.current.onVisible(),5000);
   
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    const { carImages } = this.props;
    const title = this.props.title;
    return (
      <Box2>
        
        <Lightbox carImages={carImages} onClick={this.openModal}/>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
{/*
          <button onClick={this.closeModal}>close</button>*/}
            <Overlay onClick={this.closeModal}> 
      <Title> {title} </Title> 
            <ServiceIcon> <FaTimes /> </ServiceIcon>
  </Overlay>
          <FullLightbox carImages={carImages} ref={this.swiperRef} />


        </Modal>
        <FullHW onClick={this.openModal}>
        </FullHW>
      </Box2>
    );
  }
}
