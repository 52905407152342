import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Swiper from 'react-id-swiper'

const LightboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto, 1fr);
  grid-gap: 5px;
`;

const swiperParams = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        centeredSlides: true,
        autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      }
    };

export default class Lightbox extends Component {
  static propTypes = {
    carImages: PropTypes.array.isRequired, // eslint-disable-line
  }


  static state = {
    open: false,
  }

  render() {
    const { carImages } = this.props;
    return (
      <div>
        {carImages.map(image => (
          <Img
            key={image.node.childImageSharp.fixed.src}
            fixed={image.node.childImageSharp.fixed}
          />
        ))}
      </div>
    );
  }
}