import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Lightbox from './lightbox';

const PortfolioImages = () => (
  <StaticQuery
    query={graphql`
      query {
        carImages:   allFile(filter: {relativeDirectory: {in: "portfolio/angelicas-cafe"}})  {
          edges {
            node {
              childImageSharp {
                sizes(maxWidth: 2000) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    
    render={data => <Lightbox title="Angelica's Cafe" carImages={data.carImages.edges} />}
  />
);
export default PortfolioImages;